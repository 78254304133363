jQuery(document).ready(function () {
	jQuery('.copy h1').each(function () {
		// jQuery(this).wrapAll("<div class='acc-btn'></div>");
		jQuery(this).nextUntil('h1')
		.wrapAll("<div class='acc-content'></div>")
		.wrapAll("<div class='acc-content-inner'></div>");
	});

	jQuery('.copy h1').each(function () {
		jQuery(this).wrapAll("<div class='acc-btn'></div>");
		//console.log(jQuery(this).nextUntil('h1')
		//    .wrapAll("<div class='acc-content'></div>")
		//    .wrapAll("<div class='acc-content-inner'></div>"));
	});

	var animTime = 300,
			clickPolice = false;

	jQuery(document).on('touchstart click', '.acc-btn', function () {
		if (!clickPolice) {
			clickPolice = true;

			var currIndex = jQuery(this).index('.acc-btn'),
					targetHeight = jQuery('.acc-content-inner').eq(currIndex).outerHeight(),
					jQueryh1 = jQuery(this).find('h1'),
					jQuerycontent = jQuery('.acc-content').eq(currIndex);

			jQuery('.acc-btn h1').not(jQueryh1).removeClass('selected');
			jQueryh1.toggleClass('selected');

			jQuery('.acc-content').not(jQuerycontent).stop().animate({
				height: 0
			}, animTime);
			console.log(jQuerycontent.get(), targetHeight, jQueryh1.hasClass('selected') ? targetHeight : 0)
			jQuerycontent.stop().animate({
				height: jQueryh1.hasClass('selected') ? targetHeight : 0
			}, animTime);

			setTimeout(function () {
				clickPolice = false;
			}, animTime);
		}

	});

});
